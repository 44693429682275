import type { Dispatch } from '@reduxjs/toolkit';
import { flushFilterSports, flushInteractions } from './actionsInteractions';
import { processDispatch } from './common/dispatcher';
import type { CriteriasPostPayloadType } from '~/api/apiUsers';
import { apiUsers } from '~/api/apiUsers';
import { apiConnect } from '~/api/apiConnect';
import {
  getResponseData,
  getResponseDataCollection,
} from '~/api/common/callApi';
import type { UpdatingDataCriteria } from '~/components/Profile/model';
import { actions } from '~/const/actions';
import type { CriteriaChoiceUserType } from '~/model/CriteriaChoiceType';
import type { UserInteractionStatusType } from '~/model/InteractionType';
import { actionsProjects } from '~/actions/actionsProjects';
import type { SportType } from '~/model/SportType';
import type { UserType } from '~/model/UserType';
import type { LocaleType, UriType } from '~/model/GlobalTypes';
import { CookieName } from '~/const/appConst';

export const flushUserInteractionStatus = () => ({
  type: actions.FLUSH_USER_INTERACTION_STATUS,
});

export const getUser = (user, expirationDate: Date) => ({
  expirationDate: JSON.stringify(expirationDate),
  type: actions.GET_USER,
  user,
});

const getUserInteractionStatus = (data: UserInteractionStatusType) => ({
  payload: data,
  type: actions.GET_USER_INTERACTION_STATUS,
});

export const updateUserInteractionStatus = (
  data: UserInteractionStatusType,
) => ({
  payload: data,
  type: actions.UPDATE_USER_INTERACTION_STATUS,
});

export const putUser = user => ({
  type: actions.PUT_USER,
  user,
});

const getChild = (child: UserType) => ({
  child,
  type: actions.GET_CHILD,
});

const getChildren = children => ({
  children,
  type: actions.GET_CHILDREN,
});

const getUserCriterias = payload => ({
  payload,
  type: actions.GET_USER_CRITERIAS,
});

const postUserCriterias = (
  payload: CriteriaChoiceUserType[],
  childId?: number,
) => ({
  childId,
  payload,
  type: actions.POST_USER_CRITERIAS,
});

const updateUserCriterias = (
  payload: CriteriaChoiceUserType[],
  childId?: number,
) => ({
  childId,
  payload,
  type: actions.UPDATE_USER_CRITERIAS,
});

const postUserCriteria = criteria => ({
  criteria,
  type: actions.POST_USER_CRITERIA,
});

const deleteUserCriteria = (criteriaId, childId) => ({
  childId,
  criteriaId,
  type: actions.DELETE_USER_CRITERIA,
});

const putUserData = (data, keyAttribute, childId) => ({
  childId,
  data,
  keyAttribute,
  type: actions.PUT_USER_DATA,
});

const postChild = children => ({
  children,
  type: actions.POST_CHILD,
});

const putChild = child => ({
  child,
  type: actions.PUT_CHILD,
});

const postChildCriteria = (criteria, childId) => ({
  childId,
  criteria,
  type: actions.POST_CHILD_CRITERIA,
});

const deleteChild = children => ({
  children,
  type: actions.DELETE_CHILD,
});

export const initUserAction = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actions.GET_USER,
      user: {},
    });
  };
};

const getUserAction = (locale: LocaleType) => {
  return (dispatch: Dispatch) => {
    const token = apiConnect.getAuthenticationToken(CookieName.ACCESS_TOKEN);
    const expirationDate = apiConnect.getTokenExpirationDate(token);
    return processDispatch(
      {
        dispatch,
        request: apiUsers.getUser(locale),
        action: getUser,
        response: getResponseData,
      },
      expirationDate,
    );
  };
};

const getUserInteractionStatusAction = (
  interactionId: number,
  userUri: UriType = null,
  route: string = null,
  childId: number = null,
  locale?: LocaleType,
  updateContribution?: boolean,
) => {
  return (dispatch: Dispatch): Promise<UserInteractionStatusType> => {
    return processDispatch({
      dispatch,
      request: apiUsers.getUserInteractionStatus(
        interactionId,
        userUri,
        route,
        childId,
        locale,
      ),
      action: updateContribution
        ? updateUserInteractionStatus
        : getUserInteractionStatus,
      response: getResponseData,
    });
  };
};

const getChildAction = (id: number, locale: LocaleType, alreadyLoadedSports?: SportType[]) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiUsers.getChild(id, locale, alreadyLoadedSports),
      action: getChild,
      response: getResponseData,
    });
  };
};

const getChildrenAction = () => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiUsers.getChildren(),
      action: getChildren,
      response: getResponseDataCollection,
    });
  };
};

const getUserCriteriasAction = (
  user: UserType,
  locale: LocaleType,
  filters?: string[],
) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiUsers.getUserCriterias(user, locale, filters),
      action: getUserCriterias,
    });
  };
};

const postUserCriteriasAction = (
  postData: CriteriasPostPayloadType[],
  childId?: number,
) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiUsers.postUserCriterias(postData, childId),
      action: childId ? null : postUserCriterias,
      response: getResponseData,
    });
  };
};

const putUserAction = (
  postData: Record<string, any>,
  locale: LocaleType,
  alreadyLoadedSports?: SportType[],
  guestId?: number,
) => {
  return (dispatch: Dispatch) => {
    if (postData?.sports) {
      dispatch(flushInteractions());
      dispatch(flushFilterSports());
    }
    return processDispatch({
      dispatch,
      request: apiUsers.putUser(
        postData,
        locale,
        alreadyLoadedSports,
        guestId,
      ),
      action: putUser,
      response: getResponseData,
    });
  };
};

const putUserAcceptCurrentTermsOfUseAction = () => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiUsers.putUserAcceptCurrentTermsOfUse(),
      action: putUser,
      response: getResponseData,
    });
  };
};

const postUserCriteriaAction = (postData) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiUsers.postUserCriteria(postData),
      action: postUserCriteria,
      response: getResponseData,
    });
  };
};

const updateUserCriteriasAction = (
  data: UpdatingDataCriteria[],
  userLanguage?: string,
  childId?: number,
  fromSelection?: boolean,
) => {
  return (dispatch: Dispatch) => {
    return processDispatch(
      {
        dispatch,
        request: apiUsers.updateUserCriterias(data, userLanguage, childId),
        action: fromSelection && childId ? null : updateUserCriterias,
        response: getResponseData,
      },
      childId,
    );
  };
};

const deleteUserCriteriaAction = (criteriaId: number, childId?: number) => {
  return (dispatch: Dispatch) => {
    return processDispatch(
      {
        dispatch,
        request: apiUsers.deleteUserCriteria(criteriaId),
        action: deleteUserCriteria,
        response: () => criteriaId,
      },
      childId,
    );
  };
};

const putUserDataAction = (
  keyAttribute: string,
  postData: Partial<
    Omit<UserType, 'sports'> & {
      sports: UriType[];
    }
  >,
  childId?: number,
  userLanguage?: LocaleType,
  guestUserId?: number,
) => {
  return (dispatch: Dispatch) => {
    if (postData?.sports) {
      dispatch(actionsProjects.flushFollowedProjects());
      dispatch(actionsProjects.flushProjects());
      dispatch(actionsProjects.flushUserProjects());
      dispatch(flushFilterSports());
    }
    return processDispatch(
      {
        dispatch,
        request: apiUsers.putUserData(
          postData,
          childId,
          userLanguage,
          guestUserId,
        ),
        action: putUserData,
        response: getResponseData,
      },
      keyAttribute,
      childId,
    );
  };
};

const deleteUserAction = (clear = true) => {
  return async (dispatch: Dispatch) => {
    return await processDispatch({
      dispatch,
      request: apiUsers.deleteUser(),
      action: () => ({
        type: actions.DELETE_USER,
      }),
    }).then(() => {
      // clear all user related infos from localStorage
      clear && apiConnect.clearStorage();
    });
  };
};

const postChildCriteriaAction = (postData, childId) => {
  return (dispatch: Dispatch) => {
    return processDispatch(
      {
        dispatch,
        request: apiUsers.postUserCriteria(postData),
        action: postChildCriteria,
        response: getResponseData,
      },
      childId,
    );
  };
};

const postChildAction = (postData, children) => {
  return (dispatch: Dispatch) => {
    if (postData?.sports) {
      dispatch(flushInteractions());
      dispatch(flushFilterSports());
    }
    return processDispatch({
      dispatch,
      request: apiUsers.postChild(postData),
      action: postChild,
      response: (res) => {
        return [...children, getResponseData(res)];
      },
    });
  };
};

const putChildAction = (id: number, postData: Record<string, any>) => {
  return (dispatch: Dispatch) => {
    return processDispatch({
      dispatch,
      request: apiUsers.putChild(id, postData),
      action: putChild,
      response: getResponseData,
    });
  };
};

const deleteChildAction = (id, children) => {
  return (dispatch: Dispatch) => {
    dispatch(flushInteractions());
    dispatch(flushFilterSports());
    return processDispatch({
      dispatch,
      request: apiUsers.deleteUser(id),
      action: deleteChild,
      response: () => {
        return [...children].filter(child => child.id !== id);
      },
    });
  };
};

export const setUserLanguage = (payload: string) => ({
  payload,
  type: actions.SET_LOCALE,
});

export const flushUserCriteriasAction = () => ({
  type: actions.FLUSH_USER_CRITERIAS,
});

export const actionsUser = {
  deleteChildAction,
  deleteUserAction,
  deleteUserCriteriaAction,
  getChildAction,
  getChildrenAction,
  getUserCriteriasAction,
  getUserAction,
  getUserInteractionStatus,
  flushUserInteractionStatus,
  flushUserCriteriasAction,
  getUserInteractionStatusAction,
  initUserAction,
  postChildAction,
  postChildCriteriaAction,
  postUserCriteriaAction,
  postUserCriteriasAction,
  updateUserCriteriasAction,
  putChildAction,
  putUserAcceptCurrentTermsOfUseAction,
  putUserAction,
  putUserDataAction,
  setUserLanguage,
};
