import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslations } from 'next-intl';
import type { AxiosError } from 'axios';
import { VpButton, VpIcon } from '@vtmn-play/react/headless';
import { GenericDialog } from '../genericDialog/GenericDialog';
import type { ErrorDialogPropsType } from './models';
import { flushErrorsAction, throwErrorsAction } from '~/actions/actionsErrors';
import { env } from '~/const/env';
import {
  contributionDuplicate,
  generateActionBtnError,
  generateContentMsgError,
  generateTitleBtnError,
  handleLogout,
  initError,
  isAccountBlocked,
  isEmpty,
  redirectToAccountVerification,
  redirectToLogin,
} from '~/utils/utils';
import { getNthLastRouteFromHistory } from '~/components/Interaction/utils';
import type { UseDispatchType } from '~/model/GlobalTypes';
import {
  errorsSelector,
  historyUrlSelector,
  userSelector,
} from '~/selectors/selectors';

export const ErrorDialog = ({ onClose }: ErrorDialogPropsType) => {
  const user = useSelector(userSelector);
  const error = useSelector(errorsSelector);
  const historyUrl = useSelector(historyUrlSelector);
  const dispatch = useDispatch<UseDispatchType>();
  const throwErrorsDispatcher = (
    res: AxiosError,
    message?: string,
    isFileUpload?: boolean,
  ) => dispatch(throwErrorsAction(initError({ res, message, isFileUpload })));

  const [errorMessageIsOpened, setErrorMessageIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { status, statusText, description, url } = error;
  const t = useTranslations();

  const toggleErrorMessage = () =>
    setErrorMessageIsOpened(!errorMessageIsOpened);
  const closeModalError = () => {
    if (onClose)
      onClose();
    dispatch(flushErrorsAction());
  };

  const currentRoute = getNthLastRouteFromHistory(historyUrl);

  const handleRedirectToLogin = () => {
    redirectToLogin(currentRoute);
  };

  const handleRedirectToAccountVerification = () => {
    const currentRoute = getNthLastRouteFromHistory(historyUrl);
    redirectToAccountVerification(currentRoute);
  };

  const reloadPage = () => window.location.reload();

  const isUnauthenticated = status === 401;
  const isBlocked = isAccountBlocked(status, description);
  const isContributionDuplicate = contributionDuplicate(error);

  const primaryButtonLabel
    = isUnauthenticated || isBlocked
      ? t(generateTitleBtnError(error))
      : t('CONTACT_DIALOG.CLOSE');
  // If is Unauthorized or is Blocked, store the appropriate action
  const specificAction
    = (isUnauthenticated && handleRedirectToLogin)
    || (isBlocked && handleRedirectToAccountVerification)
    || (isContributionDuplicate && reloadPage);
  // apply specific or default action on primary button
  const primaryButtonAction = specificAction || closeModalError;
  const secondaryButtonLabel
    = (!isBlocked
    && !isUnauthenticated
    && !isEmpty(error)
    && t(generateTitleBtnError(error)))
    || (isBlocked && t('GLOBAL.LOGOUT'));
  const secondaryButtonAction = () => {
    if (isBlocked) {
      handleLogout(setIsLoading, throwErrorsDispatcher, t);
    } else if (error && !isEmpty(error)) {
      generateActionBtnError(error);
      dispatch(flushErrorsAction());
    }
  };

  const messageContent = (
    <div className="text-center">
      <p>{!isEmpty(error) && t(generateContentMsgError(error))}</p>
      {!isUnauthenticated
      && !isBlocked
      && (!!status || statusText || description) && (
        <>
          {status === 403 && (
            <div className="error-disconnect">
              <div className="error-disconnect__info">
                <span>{t('ERROR_MESSAGE.USER_MAIL_INFO')}</span>
                <span>{user?.mail}</span>
              </div>
              <VpButton
                variant="tertiary"
                onClick={() => handleLogout(
                  setIsLoading,
                  throwErrorsDispatcher,
                  t,
                  currentRoute,
                )}
              >
                {t('ERROR_DIALOG.USE_ANOTHER_ACCOUNT')}
              </VpButton>
            </div>
          )}
          <div className="error-infos">
            <VpButton
              variant="tertiary"
              size="small"
              onClick={toggleErrorMessage}
              startSlot={(
                <VpIcon
                  name={errorMessageIsOpened ? 'chevron-down' : 'chevron-up'}
                />
              )}
            >
              {errorMessageIsOpened
                ? t('ERROR_DIALOG.NO_DISPLAY')
                : t('ERROR_DIALOG.DISPLAY')}
            </VpButton>
            {errorMessageIsOpened && (
              <>
                {!!status && (
                  <>
                    <br />
                    {t('ERROR_DIALOG.STATUS')}
                    {' '}
                    {status}
                    {' '}
                    {statusText}
                  </>
                )}
                {description && (
                  <>
                    <br />
                    {t('ERROR_DIALOG.DESCRIPTION')}
                    {' '}
                    {description}
                    {' '}
                  </>
                )}
                {url && (
                  <>
                    <br />
                    {t('ERROR_DIALOG.ROUTE')}
                    {' '}
                    {url}
                    {' '}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );

  return (
    !env.DISABLED_ERRORS_MSG
    && error
    && Object.keys(error).length > 0 && (
      <GenericDialog
        isOpen
        isClosable={!isUnauthenticated && !isBlocked}
        onClose={closeModalError}
        content={messageContent}
        isLoading={isLoading}
        primaryButtonTitle={primaryButtonLabel}
        primaryButtonClick={primaryButtonAction}
        secondaryButtonTitle={secondaryButtonLabel}
        secondaryButtonClick={secondaryButtonAction}
      />
    )
  );
};
