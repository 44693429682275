import { useRouter } from 'next/router';
import { type PropsWithChildren, createContext, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import classNames from 'classnames';
import { useLocale } from 'next-intl';
import { AxiosProvider } from '~/utils/axiosInterceptorResponse';
import type { UseDispatchType } from '~/model/GlobalTypes';
import { getAuthenticated } from '~/actions/actionsAccount';
import { actionsUser, putUser } from '~/actions/actionsUser';
import Authentication from '~/components/Authentication/Authentication';
import { Maintenance } from '~/components/Maintenance/Maintenance';
import {
  authenticatedSelector,
  maintenanceSelector,
  userLanguageSelector,
  userSelector,
} from '~/selectors/selectors';
import { usePageView } from '~/hooks/analytics/page-view/usePageView';
import type { UserType } from '~/model/UserType';
import { dataLayerPush } from '~/utils/analytics/dataLayerPush';
import { AnalyticEvents, entityRoutes } from '~/const/appConst';
import { isAuthenticatedAsMainUser, isGuestUser } from '~/utils/user/user';
import { useGuest } from '~/hooks/interaction/useGuest';
import { useHistory } from '~/hooks/history/useHistory';
import { getLanguageFromLocale } from '~/utils/utils';
import { useScroll } from '~/hooks/scroll/useScroll';
import { usePathname } from '@/navigation';

export const ScrollContext = createContext({ isScrollingUp: false });

export const Layout = ({ children }: PropsWithChildren) => {
  const locale = useLocale();
  const pathname = usePathname();
  const router = useRouter();
  const store = useStore();
  const dispatch = useDispatch<UseDispatchType>();

  const authenticated = useSelector(authenticatedSelector);
  const user = useSelector(userSelector);
  const userLanguage = useSelector(userLanguageSelector);
  const maintenance = useSelector(maintenanceSelector);

  const { id, logout } = useGuest(false, user);
  const { isScrollingUp, scroll } = useScroll();

  usePageView(user, userLanguage);
  useHistory();

  const getUserDispatcher = () => dispatch(actionsUser.getUserAction(userLanguage));
  const initUserDispatcher = () => dispatch(actionsUser.initUserAction());

  const putUserDispatcher = (
    user: Partial<UserType>,
    authenticated: boolean,
  ) => {
    dispatch(putUser(user));
    dispatch(getAuthenticated(authenticated));
  };

  AxiosProvider.Instance.setup(store);

  const pageName
    = (pathname === '/' && '-home')
    || pathname.split('/').join('-').split('?').shift();

  useEffect(() => {
    if (isAuthenticatedAsMainUser(user, authenticated)) {
      dataLayerPush({
        event: AnalyticEvents.CONNECTED,
        page_name: pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, authenticated]);

  useEffect(() => {
    if (!isGuestUser(user))
      return;

    const isValidGuestPath = (url: string) => {
      if (
        router.pathname === `/[locale]/${entityRoutes.project}/[id]`
        || router.pathname === `/[locale]/${entityRoutes.interaction}/[id]`
      ) {
        return true;
      }

      const pattern = new RegExp(
        `^/(?:${getLanguageFromLocale(userLanguage)}/)?${entityRoutes.survey}/${id}$`,
      );

      return pattern.test(url);
    };

    const handleRouteChange = (url: string) => {
      !isValidGuestPath(url) && logout();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, user?.['@id']]);

  useEffect(() => {
    Authentication(
      locale,
      pathname,
      router,
      initUserDispatcher,
      getUserDispatcher,
      putUserDispatcher,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return maintenance
    ? (
      <Maintenance />
      )
    : (
      <ScrollContext.Provider value={{ isScrollingUp }}>
        <div
          data-testid="layout"
          className={classNames('page-layout', {
            [`page${pageName}`]: pageName,
            'not-scrolled': scroll === 0,
            scrolled: scroll > 0,
            'is-scrolling-up': isScrollingUp,
          })}
        >
          {children}
        </div>
      </ScrollContext.Provider>
      );
};
