import type { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  deleteRequestBuilder,
  getRequestBuilder,
  getResponseData,
  getResponseDataCollection,
  getUnpaginatedCollection,
  postRequestBuilder,
  processCall,
  putRequestBuilder,
} from './common/callApi';
import { api, apiPaths, apiTypes, translationsQueryParam } from '~/const/api';
import { env } from '~/const/env';
import type {
  CriteriaChoiceType,
  CriteriaChoiceUserType,
} from '~/model/CriteriaChoiceType';
import type { SportType } from '~/model/SportType';

import type {
  CriteriaChoiceUserToDelete,
  UpdatingDataCriteria,
} from '~/components/Profile/model';
import type { UserInteractionStatusType } from '~/model/InteractionType';
import type { UserType } from '~/model/UserType';
import type { DateType, LocaleType, UriType } from '~/model/GlobalTypes';
import type { CriteriaType } from '~/model/CriteriaType';
import type { DataCollectionType } from '~/model/DataCollectionType';
import type { FollowedUpInformationType } from '~/components/Project/model';
import { isMainUser } from '~/utils/user/user';

// ======================================
//          CHILDREN
// ======================================

// GET CHILDREN LIGHT (without criterias and sports)
const getChildren = (): AxiosPromise<DataCollectionType<UserType>> => {
  const request: AxiosRequestConfig<DataCollectionType<UserType>>
    = getRequestBuilder(
      `${env.REACT_APP_API}${api.USERS}/${api.ME}/${api.CHILDREN}`,
    );
  return processCall(request);
};

const postChild = (postData: any) => {
  const request: AxiosRequestConfig<UserType> = postRequestBuilder(
    `${env.REACT_APP_API}${api.USERS}`,
    JSON.stringify(postData),
  );
  return processCall(request, (response): any => {
    const user = getResponseData(response);
    const urlSports = `${env.REACT_APP_API}${api.USERS}/${user.id}/${api.SPORTS}`;
    return processCall(
      getRequestBuilder(urlSports),
      (responseSports: AxiosResponse<DataCollectionType<SportType>>) => {
        const sports = getResponseDataCollection(responseSports);
        return { ...response, data: { ...user, sports } };
      },
    );
  });
};

const putChild = (id: number, postData: Record<string, any>) => {
  const request = putRequestBuilder(
    `${env.REACT_APP_API}${api.USERS}/${id}`,
    JSON.stringify(postData),
  );
  // If sports already exist, merge them
  return processCall(request);
};

// ======================================
//          USER
// ======================================

const getUserSports = async (user: UserType, locale: LocaleType) => {
  const requestSports = getRequestBuilder<DataCollectionType<SportType>>(
    `${env.REACT_APP_API}${isMainUser(user) ? api.USERS : api.GUEST_USERS}/${user.id}/${api.SPORTS}`,
    false,
    locale,
  );

  return processCall(requestSports);
};

// Get User with sports
export const getUser = async (locale: LocaleType, asGuest = false): AxiosPromise<UserType> => {
  const request: AxiosRequestConfig<UserType> = getRequestBuilder(
    `${env.REACT_APP_API}${asGuest ? api.GUEST_USERS : api.USERS}/${api.ME}`,
  );
  return processCall(request, async (responseUser) => {
    const user = getResponseData(responseUser);
    try {
      const responseSports = await getUserSports(user, locale);
      const sports = getResponseDataCollection(responseSports);
      return { ...responseUser, data: { ...user, sports } };
    } catch (error) {
      return error;
    }
  });
};

export const getUserData = async (userId: number, filter?: string[]) => {
  const searchParams = filter ? `?groups[]=${filter.join('&groups[]=')}` : '';
  const request = getRequestBuilder<{ contribution_count?: number }>(
    `${env.REACT_APP_API}${api.USERS}/${userId}/${api.DATA}${searchParams}`,
  );
  return processCall(request);
};

const getUserInteractionStatus = (
  interactionId: number,
  userUri: UriType = null,
  route: string = null,
  childId: number = null,
  locale?: LocaleType,
): AxiosPromise<UserInteractionStatusType> => {
  const params = new URLSearchParams();

  childId && params.append('child', `${childId}`);
  userUri && params.append('userId', `${userUri}`);
  route && params.append('route', `${route}`);

  const request: AxiosRequestConfig<UserInteractionStatusType>
    = getRequestBuilder(
      `${env.REACT_APP_API}${api.USER_INTERACTION_STATUS}/${interactionId}?${params}`,
      false,
      locale,
    );
  return processCall(request);
};

const getUserSubscription = (
  id: string,
  key: string,
): Promise<AxiosResponse> => {
  const request = getRequestBuilder(
    `${env.REACT_APP_API}${api.USERS}/${id}/${api.SUBSCRIPTION}?key=${key}`,
  );
  return processCall(request);
};

const putUserSubscription = (id: number, postData: any) => {
  const request = putRequestBuilder(
    `${env.REACT_APP_API}${api.USERS}/${id}/${api.UNSUBSCRIBE}`,
    JSON.stringify(postData),
  );
  return processCall(request);
};

// GET Child with sports and criterias
const getChild = async (
  id: number,
  locale: LocaleType,
  alreadyLoadedSports?: SportType[],
) => {
  // If `id` is specified, get child instead of user
  const request: AxiosRequestConfig<UserType> = getRequestBuilder(
    `${env.REACT_APP_API}${api.USERS}/${id}`,
  );

  return await processCall(request, async (responseUser): Promise<any> => {
    const user = getResponseData(responseUser);
    const userCriterias = getUnpaginatedCollection<CriteriaChoiceUserType[]>({
      route: `${api.USERS}/${user.id}/${api.CRITERIA_CHOICE_USERS}`,
      acceptLanguage: locale,
    });

    // Store urls in a Requests Array
    // If sports already loaded, don't use the corresponding request
    const requests:
      | [Promise<CriteriaChoiceUserType[]>]
      | [
        Promise<CriteriaChoiceUserType[]>,
        AxiosPromise<DataCollectionType<SportType>>,
      ] = alreadyLoadedSports
        ? [userCriterias]
        : [
            userCriterias,
            processCall<DataCollectionType<SportType>>(
              getRequestBuilder(
              `${env.REACT_APP_API}${api.USERS}/${user.id}/${api.SPORTS}`,
              false,
              locale,
              ),
            ),
          ];

    // Chain requests and returns an array of responses
    return await Promise.all(requests).then(
      ([criteriaChoiceUser, userSports]) => {
        // USER SPORTS (index 1)
        // If sports already loaded, use them directly
        // Else use the request response
        const sports = alreadyLoadedSports || [...getResponseDataCollection(userSports)];

        // Combined data
        const combinedData: AxiosResponse<UserType> = {
          ...responseUser,
          data: {
            ...user,
            criterion_choice_users: criteriaChoiceUser,
            sports,
          },
        };
        return combinedData;
      },
    );
  });
};

const getUserCriterias = (
  user: UserType,
  locale: LocaleType,
  filters: string[],
) => {
  const queryFilters = filters ? `&${filters.join('&')}` : '';
  return getUnpaginatedCollection<CriteriaChoiceUserType[]>({
    route: `${isMainUser(user) ? api.USERS : api.GUEST_USERS}/${user.id}/${
      api.CRITERIA_CHOICE_USERS
    }?${translationsQueryParam(
      apiPaths.CRITERIA_CHOICE,
    )}&${translationsQueryParam(apiPaths.SPORT)}
      ${queryFilters}`,
    acceptLanguage: locale,
  });
};

const getUserMandatoryCriterias = (userId: number, locale?: LocaleType) => {
  return getUnpaginatedCollection<CriteriaChoiceUserType[]>({
    route: `${api.USERS}/${userId}/${
      api.CRITERIA_CHOICE_USERS
    }?${translationsQueryParam(
      apiPaths.CRITERIA_CHOICE,
    )}&criterion.mandatory=true`,
    acceptLanguage: locale,
  });
};

const handleGraftSportsToUser = ({
  sportsResponse,
  userResponse,
}: {
  sportsResponse: AxiosResponse;
  userResponse: AxiosResponse;
}) => {
  const user = getResponseData(userResponse);
  const sports = getResponseDataCollection(sportsResponse);
  // Combined data
  return {
    ...userResponse,
    data: { ...user, sports },
  };
};

const putUser = (
  postData: Record<string, any>,
  locale: LocaleType,
  alreadyLoadedSports?: SportType[],
  guestUserId?: number,
): AxiosPromise<UserType> => {
  const request = putRequestBuilder(
    `${env.REACT_APP_API}${guestUserId ? api.GUEST_USERS : api.USERS}/${guestUserId || api.ME}`,
    JSON.stringify(postData),
  );
  return processCall(request, (userResponse: AxiosResponse) => {
    // GET USER SPORTS AFTER A PUT
    const user = getResponseData(userResponse);
    const urlSports = `${env.REACT_APP_API}${api.USERS}/${user.id}/${api.SPORTS}`;
    return alreadyLoadedSports
      ? Promise.resolve({
        ...userResponse,
        data: {
          ...user,
          sports: alreadyLoadedSports,
        },
      })
      : processCall(
        getRequestBuilder(
          urlSports,
          false,
          locale,
        ),
        (sportsResponse: AxiosResponse) =>
          handleGraftSportsToUser({ sportsResponse, userResponse }),
      );
  });
};

const putUserAcceptCurrentTermsOfUse = () => {
  const request = putRequestBuilder(
    `${env.REACT_APP_API}${api.USERS}/${api.ME}/accept-current-terms-of-use`,
    {},
  );
  return processCall(request, (userResponse: AxiosResponse<UserType>): any => {
    // GET USER SPORTS AFTER A PUT
    const user = getResponseData(userResponse);
    const urlSports = `${env.REACT_APP_API}${api.USERS}/${user.id}/${api.SPORTS}`;
    return processCall(getRequestBuilder(urlSports), sportsResponse =>
      handleGraftSportsToUser({ sportsResponse, userResponse }));
  });
};

const putUserData = (
  postData: Partial<
    Omit<UserType, 'sports'> & {
      sports: UriType[];
    }
  >,
  childId?: number,
  userLanguage?: LocaleType,
  guestUserId?: number,
) => {
  const route = guestUserId ? api.GUEST_USERS : api.USERS;
  const id = childId || guestUserId ? childId || guestUserId : api.ME;

  const request = putRequestBuilder(
    `${env.REACT_APP_API}${route}/${id}`,
    postData,
  );

  return processCall(request, (responseUser: AxiosResponse<UserType>): any => {
    const user = getResponseData(responseUser);
    const requestSports: AxiosRequestConfig<DataCollectionType<SportType>>
      = getRequestBuilder(
        `${env.REACT_APP_API}${route}/${id}/${
          api.SPORTS
        }?${translationsQueryParam(apiPaths.SPORT)}`,
      );

    return processCall(requestSports, (responseSports) => {
      const sports = userLanguage
        ? getResponseDataCollection(responseSports).map((sport) => {
          return { ...sport, label: sport.translations[userLanguage].label };
        })
        : getResponseDataCollection(responseSports);
      // Combine data
      return {
        ...responseUser,
        data: {
          ...user,
          sports,
        },
      };
    });
  });
};

const buildCriteriaChoicesRequests = (
  criteriaAnswer: CriteriaChoiceUserType,
) => {
  const type = criteriaAnswer.criterion.type;
  const urlCriteria = `${env.REACT_APP_API}${api.CRITERIAS}/${criteriaAnswer.criterion.id}`;
  const criteriaChoicesUrlsArray: [
    Promise<CriteriaChoiceType[]>,
    Promise<CriteriaChoiceUserType[]>,
    AxiosPromise<CriteriaType>,
  ] = [
    Promise.resolve([]),
    Promise.resolve([]),
    processCall<CriteriaType>(getRequestBuilder(urlCriteria)),
  ];

  // If criteria require choices, add its choices requests in Promises
  if (
    type === apiTypes.SELECT
      || type === apiTypes.RADIO
      || type === apiTypes.CHECK
  ) {
    // Store urls in a Requests Array
    criteriaChoicesUrlsArray[0] = getUnpaginatedCollection<
      CriteriaChoiceType[]
    >({
      route: `${api.CRITERIAS}/${criteriaAnswer.criterion.id}/${api.CHOICES}`,
      queryParams: translationsQueryParam(apiPaths.CRITERIA_CHOICE),
    });

    criteriaChoicesUrlsArray[1] = getUnpaginatedCollection<
      CriteriaChoiceUserType[]
    >({
      route: `${api.CRITERIA_CHOICE_USERS}/${criteriaAnswer.id}/${api.CHOICES}`,
      queryParams: translationsQueryParam(apiPaths.CRITERIA_CHOICE),
    });
  }

  // Store each axios request in a promise array
  return criteriaChoicesUrlsArray;
};

export interface CriteriasPostPayloadType {
  criteriaUri: UriType;
  criteriaType: string;
  criteriaValue: {
    choices?: UriType[];
    value_int?: number;
    value_date?: DateType;
  };
}

const postUserCriterias = (
  postData: CriteriasPostPayloadType[],
  childId: number,
) => {
  // Requests Array
  const requestsArray = [];
  // For each answer
  for (const criteria of postData) {
    // store the criteria value(s)
    const criteriaPostData = {
      criterion: criteria.criteriaUri,
      // can be {value_int: number},{value_date: DateType} or {choices: UriType[]}
      ...criteria.criteriaValue,
      ...(childId ? { created_for: `/api/${api.USERS}/${childId}` } : {}),
    };

    // store the request options with the URL and answer value(s)
    // according to the criteria to update
    const request = postRequestBuilder(
      `${env.REACT_APP_API}${api.CRITERIA_CHOICE_USERS}`,
      criteriaPostData,
    );

    // store it in the the requests Array to execute
    requestsArray.push(request);
  }

  // Chain all requests and returns an array of responses
  return Promise.all(requestsArray.map(request => processCall(request))).then(
    (responses) => {
      // store response data in an array
      const responsesDataArray = responses.map(response =>
        getResponseData(response),
      );
      // return only the last response
      const lastResponse = responses.pop();
      // and replace data response with all data responses
      return {
        ...lastResponse,
        data: responsesDataArray,
      };
    },
  );
};

const buildRequest = (item, payloadBase, userLanguage?) => {
  if (item.value === null && item.userCriteriaChoiceId) {
    return deleteRequestBuilder(
      `${env.REACT_APP_API}${api.CRITERIA_CHOICE_USERS}/${item.userCriteriaChoiceId}`,
    );
  } else if (item.userCriteriaChoiceId) {
    return putRequestBuilder(
      `${env.REACT_APP_API}${api.CRITERIA_CHOICE_USERS}/${
        item.userCriteriaChoiceId
      }?${translationsQueryParam(apiPaths.CRITERIA_CHOICE)}`,
      { ...payloadBase },
      userLanguage,
    );
  } else {
    return postRequestBuilder(
      `${env.REACT_APP_API}${
        api.CRITERIA_CHOICE_USERS
      }?${translationsQueryParam(apiPaths.CRITERIA_CHOICE)}`,
      {
        ...payloadBase,
        criterion: item.criteriaUri,
      },
      userLanguage,
    );
  }
};

const updateUserCriterias = async (
  data: UpdatingDataCriteria[],
  userLanguage: string,
  childId?: number,
) => {
  const requests: AxiosRequestConfig[] = [];

  // remove item if empty choices and not already exists
  // to avoid adding an empty criteria
  const items = data.filter(item => !item.ignore);

  // For each criteria
  for (const item of items) {
    // store the criteria value(s)
    const payloadBase = {
      ...item.value,
      ...(childId && { created_for: `/api/${api.USERS}/${childId}` }),
    };

    // store the request options with the URL and answer value(s)
    // according to the criteria to update
    const request = buildRequest(item, payloadBase, userLanguage);

    // store it in the the requests Array to execute
    requests.push(request);
  }

  // Chain all requests and returns an array of responses
  return Promise.all(requests.map(r => processCall(r))).then(
    (
      responses: Array<
        AxiosResponse<{
          data: CriteriaChoiceUserType & CriteriaChoiceUserToDelete;
        }>
      >,
    ) => {
      // store response data in an array
      const responsesDataArray = responses.map((response, index) =>
        data[index].value
        // return request data value for post and put (if value is truthy)
          ? getResponseData(response)
        // else, return specific object (CriteriaChoiceUserToDelete) for deletion from store
          : {
              '@id': data[index].criteriaUri,
              toDelete: true,
            },
      );
      // return only the last response
      const lastResponse = responses.pop();
      // and replace data response with all data responses
      return {
        ...lastResponse,
        data: responsesDataArray,
      };
    },
  );
};

const postUserCriteria = (postData: any, onlyApiCall?: boolean) => {
  const request: AxiosRequestConfig<CriteriaChoiceUserType>
    = postRequestBuilder(
      `${env.REACT_APP_API}${api.CRITERIA_CHOICE_USERS}`,
      postData,
    );
  if (onlyApiCall)
    return processCall(request);
  return processCall(request, (response): any => {
    const criteria_answer = getResponseData(response);
    // Chain requests and returns an array of responses
    return Promise.all(buildCriteriaChoicesRequests(criteria_answer))
      .then((responsesChoicesArray) => {
        const choices = responsesChoicesArray[0];
        const user_choices = responsesChoicesArray[1];
        const sports = getResponseData(responsesChoicesArray[2]).sports;
        const criteria = {
          ...criteria_answer.criterion,
          choices,
          sports,
        };
        // Combine data
        return {
          ...response,
          data: {
            ...criteria_answer,
            criteria,
            user_choices,
          },
        };
      })
      .catch(error => error);
  });
};

const deleteUser = (childId?: number) => {
  const request = putRequestBuilder(
    `${env.REACT_APP_API}${api.USERS}/${childId || 'me'}/anonymize`,
    {},
  );
  return processCall(request);
};

const deleteUserCriteria = (criteriaId?: number) => {
  const request = deleteRequestBuilder(
    `${env.REACT_APP_API}${api.CRITERIA_CHOICE_USERS}/${criteriaId}`,
  );
  return processCall(request);
};

// ======================================
//          CONTACT
// ======================================

const postContact = (postData) => {
  const request = postRequestBuilder(
    `${env.REACT_APP_API}${api.CONTACT}`,
    JSON.stringify(postData),
  );
  return processCall(request);
};

export const getUserFollowedUpProjects = (
  projectId: number,
): AxiosPromise<DataCollectionType<FollowedUpInformationType>> => {
  const request: AxiosRequestConfig<
    DataCollectionType<FollowedUpInformationType>
  > = getRequestBuilder(
    `${env.REACT_APP_API}${api.USERS}/${api.ME}/${api.FOLLOWED_UP_PROJECTS}?project=${projectId}`,
  );
  return processCall(request);
};

export const setUserFollowedUpProjects = (
  followedUpProjectId,
  postData,
): AxiosPromise<FollowedUpInformationType> => {
  const request = followedUpProjectId
    ? putRequestBuilder(
        `${env.REACT_APP_API}${api.FOLLOWED_UP_PROJECTS}/${followedUpProjectId}`,
        postData,
    )
    : postRequestBuilder(
        `${env.REACT_APP_API}${api.FOLLOWED_UP_PROJECTS}`,
        postData,
    );
  return processCall(request);
};

export const apiUsers = {
  deleteUser,
  deleteUserCriteria,
  getChildren,
  getChild,
  getUserCriterias,
  getUserMandatoryCriterias,
  getUserFollowedUpProjects,
  getUser,
  getUserInteractionStatus,
  getUserSubscription,
  putUserSubscription,
  postChild,
  postContact,
  postUserCriteria,
  postUserCriterias,
  setUserFollowedUpProjects,
  updateUserCriterias,
  putChild,
  putUser,
  putUserAcceptCurrentTermsOfUse,
  putUserData,
};
